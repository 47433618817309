exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-index-js": () => import("./../../../src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-avaliacao-de-risco-index-js": () => import("./../../../src/pages/avaliacao-de-risco/index.js" /* webpackChunkName: "component---src-pages-avaliacao-de-risco-index-js" */),
  "component---src-pages-cnpj-index-js": () => import("./../../../src/pages/cnpj/index.js" /* webpackChunkName: "component---src-pages-cnpj-index-js" */),
  "component---src-pages-coberturas-index-js": () => import("./../../../src/pages/coberturas/index.js" /* webpackChunkName: "component---src-pages-coberturas-index-js" */),
  "component---src-pages-dados-da-empresa-index-js": () => import("./../../../src/pages/dados-da-empresa/index.js" /* webpackChunkName: "component---src-pages-dados-da-empresa-index-js" */),
  "component---src-pages-exposicao-politica-index-js": () => import("./../../../src/pages/exposicao-politica/index.js" /* webpackChunkName: "component---src-pages-exposicao-politica-index-js" */),
  "component---src-pages-finalizar-proposta-index-js": () => import("./../../../src/pages/finalizar-proposta/index.js" /* webpackChunkName: "component---src-pages-finalizar-proposta-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacoes-do-seguro-index-js": () => import("./../../../src/pages/informacoes-do-seguro/index.js" /* webpackChunkName: "component---src-pages-informacoes-do-seguro-index-js" */),
  "component---src-pages-nao-autorizado-index-js": () => import("./../../../src/pages/nao-autorizado/index.js" /* webpackChunkName: "component---src-pages-nao-autorizado-index-js" */),
  "component---src-pages-perguntas-frequentes-index-js": () => import("./../../../src/pages/perguntas-frequentes/index.js" /* webpackChunkName: "component---src-pages-perguntas-frequentes-index-js" */),
  "component---src-pages-resumo-index-js": () => import("./../../../src/pages/resumo/index.js" /* webpackChunkName: "component---src-pages-resumo-index-js" */),
  "component---src-pages-sinistralidade-index-js": () => import("./../../../src/pages/sinistralidade/index.js" /* webpackChunkName: "component---src-pages-sinistralidade-index-js" */),
  "component---src-pages-sistemas-protecionais-index-js": () => import("./../../../src/pages/sistemas-protecionais/index.js" /* webpackChunkName: "component---src-pages-sistemas-protecionais-index-js" */),
  "component---src-pages-sucesso-index-js": () => import("./../../../src/pages/sucesso/index.js" /* webpackChunkName: "component---src-pages-sucesso-index-js" */)
}

