import React, { useState, useEffect } from 'react';

export const DataContext = React.createContext();

const setLocalStorage = (key, value) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

const getLocalStorage = (key, initialValue) => {
  if (typeof window !== 'undefined') {
    const persistData = window.localStorage.getItem(key);
    return persistData !== 'undefined'
      ? JSON.parse(window.localStorage.getItem(key))
      : undefined;
  }
};

const DataProvider = (props) => {
  const [responseMotor, setResponseMotor] = useState(() =>
    getLocalStorage('responseMotor')
  );

  const [quotation, setQuotation] = useState(() =>
    getLocalStorage('quotation')
  );
  const [originalQuotation, setOriginalQuotation] = useState(() =>
    getLocalStorage('originalQuotation')
  );

  const [brokerData, setBrokerData] = useState(() =>
    getLocalStorage('brokerData')
  );

  const [activities, setActivities] = useState(() =>
    getLocalStorage('activities')
  );

  const [protectionSystems, setProtectionSystems] = useState(() =>
    getLocalStorage('protectionSystems')
  );

  const [dismissibleGlobalError, setDismissibleGlobalError] = useState(() =>
    getLocalStorage('dismissibleGlobalError')
  );

  const [modal, setModal] = useState(() => getLocalStorage('modal'));

  const today = new Date();
  const startDay = new Date('2023-10-01 00:00:00 GMT-0300');
  const limitDay = new Date('2023-11-01 00:00:00 GMT-0300');

  useEffect(() => {
    setLocalStorage('quotation', quotation);
  }, [quotation]);

  useEffect(() => {
    setLocalStorage('originalQuotation', originalQuotation);
  }, [originalQuotation]);

  useEffect(() => {
    setLocalStorage('brokerData', brokerData);
  }, [brokerData]);

  useEffect(() => {
    setLocalStorage('protectionSystems', protectionSystems);
  }, [protectionSystems]);

  useEffect(() => {
    setLocalStorage('activities', activities);
  }, [activities]);

  useEffect(() => {
    setLocalStorage('responseMotor', responseMotor);
  }, [responseMotor]);

  useEffect(() => {
    setLocalStorage('dismissibleGlobalError', dismissibleGlobalError);
  }, [dismissibleGlobalError]);

  const value = {
    responseMotor,
    setResponseMotor,
    quotation,
    setQuotation,
    originalQuotation,
    setOriginalQuotation,
    brokerData,
    setBrokerData,
    modal,
    setModal,
    activities,
    setActivities,
    protectionSystems,
    setProtectionSystems,
    dismissibleGlobalError,
    setDismissibleGlobalError,
    today,
    startDay,
    limitDay,
  };

  return (
    <DataContext.Provider value={value}>{props.children}</DataContext.Provider>
  );
};

const dataProviderWrapper = ({ element }) => (
  <DataProvider>{element}</DataProvider>
);

// const updateContext = (newData) => {
//   setData(newData);
//   setLocalStorage('data', newData);
// };

// export { updateContext };
export default dataProviderWrapper;
